import DbManager from 'globalApi/DbManager'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import dayjs from 'dayjs';
import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import SelectButton from 'buttons/SelectButton';
import DateSelector from 'buttons/DateSelector';
import VendorReportTable from './VendorReportTable';

// import {stores, StoresDepartments} from './vendorData';

const VendorReport =  ({vendor}) => {
  const [vendorInfo,setVendorInfo] = useState([]);
  const [vendorStoreNDepartInfo,setVendorStoreNDepartInfo] = useState({});
  const [stores, setStores] = useState('');
  const [storesDepartments, setStoresDepartments] = useState('');
  const [store, setStore] = useState('');
  const [menuloading, setMenuLoading] = useState(true); 
  const [loading, setLoading] = useState(true); 
  
  const [department, setDepartment] = useState('');
  const [startDate, setStartDate] = useState(dayjs('2024-08-17'));
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedData, setSelectedData] = useState({});
  const [totalQt,setTotalQt] = useState(0);
  const [totalSales,setTotalSales] = useState(0);
  useEffect( () =>{
    const fetchData = async ()=>{
      const getVendorsData = await DbManager.GET(`/achieve/vendorsStoreNDepart?vendor=${vendor}`)
      
      if(getVendorsData){
        const storeNames = getVendorsData.map(item => item.store_name);
        const departmentNames = [...new Set(getVendorsData.map(item =>item.department_name))];
        setStores(storeNames);
        setStoresDepartments(departmentNames);
        setMenuLoading(false);
      }else {
        console.error("no data received");
      }   
    }
    fetchData();
  }
    ,[vendor])



  const retriveDataBasedOnUserInfo = async (e) =>{
    e.preventDefault();
    const convertedStartDate = startDate.format('YYYY-MM-DD');
    const convertedEndDate = endDate.format('YYYY-MM-DD');

    
    const retrieveData = await DbManager.GET(`/achieve/vendors?store=${store}&department=${department}&startDate=${convertedStartDate}&endDate=${convertedEndDate}`);
    if(retrieveData){
      const accumulateByItem =  retrieveData.reduce((acc, item) => {
        if(!acc[item.sub_c_name]){
          acc[item.sub_c_name] = {
            categoryN: item.sub_c_name,
            totalPrice: item.price,
            totalTax1: item.tax1 || 0.00, 
            totalTax2: item.tax2 || 0.00 ,
            totalQt : item.qt

        };
        }else{
          acc[item.sub_c_name].totalPrice += (item.price);
          acc[item.sub_c_name].totalQt +=item.qt
        }
        return acc;
    },{});
    const resultArray = Object.values(accumulateByItem);
    const entireAccumulatedTotal = resultArray.reduce((acc, item) => {
      acc.totalRevenue = (acc.totalRevenue || 0) + item.totalPrice * (1 + item.totalTax1 + item.totalTax2);
      acc.totalQuantity = (acc.totalQuantity || 0) + item.totalQt;
  
      return acc;
  }, { totalRevenue: 0, totalQuantity: 0 }); 
    setTotalSales(entireAccumulatedTotal.totalRevenue);
    setTotalQt(entireAccumulatedTotal.totalQuantity);
 
    // console.log(finalResult);
    setVendorInfo(resultArray);
    const selectData = {"store":store, "department": storesDepartments, "startDate": startDate, "endDate": endDate};

    setSelectedData(selectData)
    setLoading(false); 
    }
    // console.log(retrieveData);
  }
 
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
      <Box sx={{width: '70vw', display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column'}}>
        <Box display="flex" marginTop="20px" >
        {menuloading ===true ? <></> : 
        <>  
            <Box sx={{display: 'flex', gap: 2}}>
              <Box sx={{display: 'flex', gap: 2}}>
                <SelectButton   
                              setData = {setStore} 
                              selected = {store} 
                              title={"Store"} 
                              data={stores} />
                <SelectButton setData = {setDepartment} 
                              selected = {department} 
                              title={"Department"} 
                              data={storesDepartments}/>
              </Box>
              <DateSelector setStartDate = {setStartDate} 
                            setEndDate = {setEndDate} 
                            endDate = {endDate} 
                            startDate = {startDate}/>
            </Box> 
          </>
                        }
          
          <ButtonGroup aria-label="Basic button group" sx={{marginLeft: "15px"}}>
            <Button onClick={retriveDataBasedOnUserInfo} variant="contained" color='success' sx={{backgroundColor: "#129d58", width:"6vw"}}>조회</Button>
          </ButtonGroup>
        </Box>
          <VendorReportTable 
                            // store={store} 
                            // storesDepartments = {storesDepartments} 
                            // startDate = {startDate}
                            // endDate = {endDate}
                            selectedData = {selectedData}
                            totalSales = {totalSales}
                            totalQt = {totalQt}
                            vendorInfo = {vendorInfo}
                            loading = {loading}
                            />
      </Box>
    </Box>
  )
}

export default VendorReport




