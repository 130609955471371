import React, { useState } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';

const DateSelector = ({setStartDate, setEndDate, endDate, startDate}) => {
  
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex',  gap: 2 }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          sx={{ maxWidth: 150}}
          onChange={(newValue) => {
            setStartDate(newValue);
            if (endDate.isBefore(newValue)) {
              setEndDate(newValue);
            }
          }}
        />
        <DatePicker
          label="End Date"
          sx={{ maxWidth: 150 }}
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          minDate={startDate} 
        />
      </Box>
    </LocalizationProvider>
    </div>
  )
}

export default DateSelector
