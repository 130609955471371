import React from 'react'
import * as ExcelJS from 'exceljs';
import excel from '../img/excel32.png'

const ExportExcel = ({vendorInfo,selectedData,totalSales,totalQt,startDate,endDate}) => {
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        worksheet.addRow({});
        worksheet.addRow({});

        worksheet.addRow(['','','','','','매장:',selectedData.store,'부서: ',selectedData.department[0]]);
        worksheet.addRow(['','','','','','전체 총 매출:','$'+totalSales,'전체 총 수량:',totalQt]);
        worksheet.addRow({})
        worksheet.addRow(['','','','','기간별   Group     매출액  집계현황']);

        worksheet.getRow(6).font = {bold: true, size: 14}
        worksheet.addRow({})
        worksheet.addRow(['조회기간:',selectedData.startDate.format('YYYY-MM-DD') +'-' +selectedData.endDate.format('YYYY-MM-DD')]);
        

        // 헤더 추가 및 스타일 설정
        worksheet.addRow(['','','구분', '매출액', 'Tax1', 'Tax2','수량','매출총액']);
        worksheet.getRow(9).font = { bold: true, size: 12, color: {argb: 'FFFFFFFF'} };
        worksheet.getRow(9).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '129d58' }
        };
        

        // 데이터 추가
        vendorInfo.forEach(item => {
            worksheet.addRow(['','',item.categoryN,'$ '+ item.totalPrice, item.totalTax1, item.totalTax2, item.totalQt,'$ '+ item.totalPrice]);
        });

        // 데이터 정렬
        worksheet.columns.forEach(column => {
            column.width = 15; // 각 열의 너비 설정
        });

        // 파일 다운로드
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.xlsx';
        link.click();
    };

    return (
        <button style={{border:"none"}} onClick={exportToExcel}>
         <img src={excel} alt="excel" />
        </button>
    );
}

export default ExportExcel
