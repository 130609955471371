import React from 'react'
import { Box,Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Sheet } from '@mui/joy';
import dayjs from 'dayjs';
import ExportExcel from 'utils/ExportExcel';


const VendorReportTable = ({selectedData,store,storesDepartments,startDate,endDate,totalSales,totalQt,vendorInfo,loading}) => {

  return (
    <div>
      
      {loading ===true ? (<div></div>) :
      (<>
      
        <Box sx={{display: 'flex' , fontWeight:'bold', justifyContent:'flex-end' ,fontSize: '14px', marginRight: '80px', gap: 5, marginBottom: '30px', marginTop: '30px'}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 3, flexDirection: 'column'}}>
            <Box>매장: {selectedData.store}</Box>
            <Box>전체 총매출: ${totalSales}</Box>
            
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 3, flexDirection: 'column'}}>
            <Box>부서: {selectedData.department}</Box>
            <Box>전체 총 수량: {totalQt}</Box>
          </Box>
        </Box>
        </>)}
        
        <Box sx={{display:'flex', alignItems:'center', justifyContent: 'space-around', fontSize: '20px', marginBottom: '10px'}}>
          <strong>기간별 Group 매출액 집계현황</strong>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box>
          조회기간: {dayjs(selectedData.startDate).format("YYYY-MM-DD")} - {dayjs(selectedData.endDate).format("YYYY-MM-DD")}</Box>
          <ExportExcel vendorInfo={vendorInfo} selectedData={selectedData} totalSales={totalSales} totalQt={totalQt} startDate={startDate} endDate={endDate}/>
        </Box>             
        
        
        <Sheet variant="soft" sx={{ pt: 1, borderRadius: 'sm' }}>
            
      <Table
        stripe="odd"
        hoverRow
        sx={{
            '& caption': {
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginBottom: '1rem',
              textAlign: 'center',
            },
            '& tbody': { bgcolor: 'background.surface' },
          }}
      >
        <TableHead >
          <TableRow sx={{backgroundColor: "#129d58"}}>
            <TableCell sx={{color: 'white'}} >구분</TableCell>
            <TableCell sx={{color: 'white'}}>매출액</TableCell>
            <TableCell sx={{color: 'white'}}>Tax1</TableCell>
            <TableCell sx={{color: 'white'}}>Tax2</TableCell>
            <TableCell sx={{color: 'white'}}>수량</TableCell>
            <TableCell sx={{color: 'white'}}>매출총액</TableCell>
          </TableRow>
        </TableHead>
        
        {loading ===true ? (<div></div>) :( 
          <>
          
          <TableBody >
            
          {vendorInfo.map((row,index) => (
            <TableRow key={row.totalPrice+index}>
              <TableCell>{row.categoryN}</TableCell>
              <TableCell>$ {row.totalPrice.toFixed(2)}</TableCell>
              <TableCell>{row.totalTax1.toFixed(2)}</TableCell>
              <TableCell>{row.totalTax2.toFixed(2)}</TableCell>
              <TableCell>&nbsp;&nbsp;{row.totalQt}</TableCell>
              <TableCell>$ {row.totalPrice.toFixed(2)*(1+row.totalTax1+row.totalTax2)}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
        </>
        )}
        
      </Table>
    </Sheet>
    
    </div>
  )
}

export default VendorReportTable
