import React, { useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectButton = ( {setData, selected, title, data} ) => {
    // const [age, setAge] = useState('');

    const handleChange = (event) => {
        setData(event.target.value);
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{title}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            sx={{ maxWidth: 120, height: 56 }}
            onChange={handleChange}
          >
            
            {data.map((store,index)=>(
                <MenuItem key ={store+index} value={store}>{store}</MenuItem>
            ))}

          </Select>
        </FormControl>
      </Box>
    )
}

export default SelectButton
