import { useState, useEffect } from "react";
import DbManager from "globalApi/DbManager";

const useDailySalesDetails = () => {
    const [data, setData] = useState({});
    const [isSalesDetailsLoading, setIsSalesDetailsLoading] = useState(true);
    // const today = new Date();
  // const startSearchDate = new Date(today);
  // startSearchDate.setDate(today.getDate() - 6);

  // const formattedToday =
  //   today.getFullYear() +
  //   ("0" + (today.getMonth() + 1)).slice(-2) +
  //   ("0" + today.getDate()).slice(-2);

  // const formatstartSearchDate =
  //   startSearchDate.getFullYear() +
  //   ("0" + (startSearchDate.getMonth() + 1)).slice(-2) +
  //   ("0" + startSearchDate.getDate()).slice(-2);

  useEffect(() => {
    const fetchData = async () => {
      setIsSalesDetailsLoading(true); 
      const resp = await DbManager.POST("/achieve/dailysales", {
        date: { start: '2024-01-01', end: '2024-01-01' },
      });
      console.log(resp);
      setData({
        date: resp.map(item => item.date.trim()),
        margin: resp.map(item => (item.margin.toFixed(2))),
        profit: resp.map(item => (item.profit.toFixed(2))),
        purchaseAmount: resp.map(item => (item.purchaseAmount.toFixed(2))),
        salesAmount: resp.map(item => (item.salesAmount.toFixed(2))),
      });
      setIsSalesDetailsLoading(false);
    };
    fetchData();
  }, []);
  return { data, isSalesDetailsLoading };
};

export default useDailySalesDetails;
